<template>
    <div class="flex flex-wrap w-4/4">

        <vx-card title="Card Request">
            <template slot="actions">
                <feather-icon icon="DownloadIcon" svgClasses="w-6 h-6 text-success" @click="exportToExcel"></feather-icon>
            </template>

            <div slot="no-body" class="mt-4">

                <vs-table :data="user.request">
                    <template slot="thead">
                        <vs-th>Name</vs-th>
                        <vs-th>Mother LastName</vs-th>
                        <vs-th>Father LastName</vs-th>
                        <vs-th>Email</vs-th>
                        <vs-th>Passport</vs-th>
                        <vs-th>Birth Date</vs-th>
                        <vs-th>Gender</vs-th>
                        <vs-th>Status</vs-th>
                        <vs-th>Created</vs-th>
                    </template>

                    <template>
                        <vs-tr :key="indextr" v-for="(client, indextr) in user.request">
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{client.firstName}}
                                        </span>
                            </vs-td>
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{client.lastNameMother}}
                                        </span>
                            </vs-td>
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{client.lastNameFather}}
                                        </span>
                            </vs-td>
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{client.email}}
                                        </span>
                            </vs-td>

                            <vs-td>
                                 <span class="flex items-center px-2 py-1 rounded">
                                            {{client.documentNumber}}
                                        </span>

                            </vs-td>
                            <vs-td>
                               <span class="flex items-center px-2 py-1 rounded">
                                            {{client.birthDate}}
                                 </span>
                            </vs-td>
                            <vs-td>
                               <span class="flex items-center px-2 py-1 rounded">
                                            {{client.gender}}
                                 </span>
                            </vs-td>
                            <vs-td>
                                <vs-chip :color="getStatusColor(client.status)" class="product-order-status">{{ getStatusText(client.status)  }}</vs-chip>
                            </vs-td>
                            <vs-td>
                                <span>{{client.created}}</span>
                            </vs-td>

                        </vs-tr>
                    </template>
                </vs-table>
            </div>

        </vx-card>
    </div>
</template>


<script>
    import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
    import DataViewSidebar from '@/components/DataViewSidebar.vue'
    import data from '../helpers/data'
    import {mapState, mapActions} from 'vuex'

    export default {
        components: {
            StatisticsCardLine,
            DataViewSidebar
        },
        data() {
            return {
                prepaidCardStatus: data.prepaidCardStatus,
                addNewDataSidebar: false,
                sidebarData: {},
                title: 'Card Import',
                selectedFormat: 'xlsx',
                fileName: 'Clients_Cactus_Prepaid_'+(new Date()).toDateString(),
                headerTitle: ['','Tarjeta','Divisa','Importe Inicial','Nombre','Apellido Paterno','Apellido Materno','F Nacim(d)','F Nacim(m)','F Nacim(aaaa)','Ciudad Nacimiento','Pais Nacimiento','Telefono','Correo Electronico','Genero', 'Calle y Numero','Ciudad','Estado','Pais','CP'],
                headerVal: ['','','cardType','', 'firstName', 'lastNameFather', 'lastNameMother','birthDay','birthMonth','birthYear','bornCity','bornCountry','phone','email', 'gender','address','city','state','country','postalCode'],
            }
        },
        created() {
            this.getCardsRequest()
        },
        computed: {
            ...mapState({
                user: state => state.user

            })

        },
        methods: {
            ...mapActions('user', ['getCardsRequest']),
            getStatusColor(status) {
                if(status == this.prepaidCardStatus.INACTIVE) return "warning"
                if(status == this.prepaidCardStatus.ACTIVE) return "success"
                if(status == this.prepaidCardStatus.DELETED || status == this.prepaidCardStatus.BLOCKED) return "danger"
                return "primary"
            },
            getStatusText(status){
                if(status == this.prepaidCardStatus.INACTIVE) return "Pending"
                if(status == this.prepaidCardStatus.ACTIVE) return "Active"
                if(status == this.prepaidCardStatus.BLOCKED) return "Blocked"
                if(status == this.prepaidCardStatus.DELETED ) return "Deleted"
                return ''
            },
            addNewData() {
                this.sidebarData = {}
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val=false) {
                this.addNewDataSidebar = val
            },
            exportToExcel () {
                import('../vendor/Export2Excel').then(excel => {
                    const list = this.user.request
                    const data = this.formatJson(this.headerVal, list)
                    excel.export_json_to_excel({
                        header: this.headerTitle,
                        data,
                        filename: this.fileName,
                        autoWidth: this.cellAutoWidth,
                        bookType: this.selectedFormat
                    })
                })
            },
            formatJson (filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    // Add col name which needs to be translated
                    // if (j === 'timestamp') {
                    //   return parseTime(v[j])
                    // } else {
                    //   return v[j]
                    // }

                    return v[j]
                }))
            }
        }


    }

</script>
